import React from "react";

const Card = () => {
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="accessibility text-center">
          <div className="acc feature-img-bg mx-auto my-3 mb-4">
            <img className="circle-icon" src="/assets/icons/why/1.png" alt="" />
          </div>
          <h3>Where Are You</h3>
          <p className="fontd9rem px-3">We make access easy because we come to you.</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="expert-care text-center">
          <div className="expert feature-img-bg mx-auto my-3 mb-4">
            <img className="circle-icon" src="/assets/icons/why/2.png" alt="" />
          </div>
          <h3>In Great Hands</h3>
          <p className="fontd9rem px-3">Our licensed physical therapists are at the top of their profession.</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="affordability text-center">
          <div className="afford feature-img-bg mx-auto my-3 mb-4">
            <img className="circle-icon" src="/assets/icons/why/3.png" alt="" />
          </div>
          <h3>Actually Affordable</h3>
          <p className="fontd9rem px-3">We know the importance of physical therapy, so we priced our services not to break the bank.</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="insurance-accepted text-center">
          <div className="insurance feature-img-bg mx-auto my-3 mb-4">
            <img className="circle-icon" src="/assets/icons/why/4.png" alt="" />
          </div>
          <h3>Insurance Welcomed</h3>
          <p className="fontd9rem px-3">Most major insurance carriers cover our services. And that's good news for everyone.</p>
        </div>
      </div>
    </div>
  )
};

export default Card;
