import React from 'react';

import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Hiw from "../components/hiw";
import Card from "../components/card";
import { Helmet } from 'react-helmet'

export const WhyPageTemplate = ({ banner, compare }) => (
  <main role="main" className="why-pete-page">
    <Helmet>
      <title>Why Pete</title>
    </Helmet>
    {/* Begin Banner */}
    <div className="jumbotron why-pete mb-0" style={{"backgroundImage": `url(${banner.bannerImage.childImageSharp ? banner.bannerImage.childImageSharp.fluid.src : banner.bannerImage})`}}>
      <div className="container text-light">
        <div className="row">
          <div className="col-md-7" />
          <div className="col-md-5 text-center">
            <h1 className="display-5 text-white mb-3">{banner.title}</h1>
            <a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">{banner.btnTitle}</a>
            <br />
            <span style={{fontSize: '17px', fontWeight: 'bold'}}>or call <a className="text-white" href={`tel:+{banner.phone}`} role="button"> {banner.phone}</a></span>
          </div>
        </div>
      </div>
    </div>
    {/* End Banner */}
    {/* Begin Second section */}
    <div className="container features card ca-shadow-sm pt-5 pb-3 mt-n4 mb-5 px-4 rounded-2">
      <Card />
    </div>
    {/* End Second section */}
    {/* Begin How it works */}
    <div className="container pt-2 pb-5 hiw">
      <Hiw />
    </div>
    {/* End How it works */}
    {/* Begin Testimonials */}
    <div className="container-fluid bg-gray mt-4 pt-5 pb-5 text-center">
      <div className="container pb-17">
        <h2 className="py-4"> What others are saying about Pete </h2>
        <p className="pb-4">The list of happy patients just keeps getting longer and longer.</p>
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row mb-md-4">
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        After shoulder labrum repair surgery, and trouble finding a physical therapist
                        in my area, and I decided to try out Pete Health. That might have been the best
                        decision I made in my shoulder rehab. Plus, I didn't have to drive in traffic.
                      </p>
                      <h3>Cassie L.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I am a physician that tore my left meniscus earlier this year. My PM &amp; R
                        doctor recommended PT over Surgery and gave me you all as a recommendation.
                        He knew I also had a busy work schedule and that you'd come to me.
                      </p>
                      <h3>Dr. Javier</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        My treatment sessions have been great! My therapist has taught me how to
                        strengthen my core and abs to take pressure off of my back. I've been struggling with
                        nerve pain and after 5 sessions, it's so much better already.
                      </p>
                      <h3>Don G.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I can't say enough good things. I love that my therapist emails me videos of the
                        stretches and exercises to do in between sessions and on my own. I recommend
                        Pete Health very highly to anyone experiencing back and sciatica.
                      </p>
                      <h3>Kendra M.</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row mb-md-4">
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        What an amazing discovery for physical therapy! I had rotator cuff surgery, and was
                        struggling to find appointments at other places, or any continuity for my post care.
                        Pete Health comes to me, and it's literally the best PT I've ever had!
                      </p>
                      <h3>Daryl P.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I fell down the stairs and my doctor wrote me a referral for physical therapy. I called
                        several places and they all were quite rude, actually. Our insurance is excellent but it
                        takes a little effort to get all of the details. Pete Health was the friendliest.
                      </p>
                      <h3>Debbie S.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I had a torn meniscus in my left knee and had to start physical therapy.
                        I started going to a physical therapist located in Hollywood, which ultimately
                        closed, but that turned out to be a blessing in disguise. That's when I found
                        Pete Health.
                      </p>
                      <h3>Myron L.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        Pete Health is absolutely wonderful. They come to my apartment twice a week for
                        my rehab sessions, PLUS they take insurance. My therapist is extremely
                        knowledgeable and even brings a table for the stretching and messaging I need.
                      </p>
                      <h3>Karen A.</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row mb-md-4">
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        This is the future of physical therapy. A couple of months ago, I badly
                        sprained my ankle playing basketball. I was looking for just a few
                        sessions with a physical therapist, and I'm glad I found Pete Health.
                      </p>
                      <h3>Danny R.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        It's a great idea to have physical therapy at your own home. The results were
                        great, and I was able to get treatment and to learn the exercises right in my
                        own home that made healing my ankle easier.
                      </p>
                      <h3>Natasha B.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        I broke my kneecap and needed surgery. My orthopedic surgeon suggested Pete Health.
                        I couldn't possibly travel for therapy with a painful knee in a big brace and Pete
                        Health actually comes to you.
                      </p>
                      <h3>Christopher W.</h3>
                    </div>
                  </div>
                  <div className="col-md-6" style={{height: '270px'}}>
                    <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
                      <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                      <p>
                        Using massage and carefully tailored exercises tailored to my needs, Pete Health's
                        physical therapist has seen me through my continuing rehabilitation. I appreciate
                        how that I am never pressured to do more than I feel I can do.
                      </p>
                      <h3>Keisha K.</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div><a className="carousel-control-prev ca-shadow-sm rounded-circle white-bg" href="#carouselExampleControls" role="button" data-slide="prev">←</a> <a className="carousel-control-next ca-shadow-sm rounded-circle white-bg" href="#carouselExampleControls" role="button" data-slide="next">→</a>
          </div>

        </div>
        <div id="mob-carousel" className="carousel slide" data-ride="carousel">
          <div>
            <ul className="carousel-indicators" />{/* The slideshow */}
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="card ca-shadow-sm border-0 p-4">
                  <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                  <p>
                    After shoulder labrum repair surgery, and trouble finding a physical therapist
                    in my area, and I decided to try out Pete Health. That might have been the best
                    decision I made in my shoulder rehab. Plus, I didn't have to drive in traffic.
                  </p>
                  <h3>Cassie L.</h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="card ca-shadow-sm border-0 p-4">
                  <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                  <p>
                    I am a physician that tore my left meniscus earlier this year. My PM &amp; R
                    doctor recommended PT over Surgery and gave me you all as a recommendation.
                    He knew I also had a busy work schedule and that you'd come to me.
                  </p>
                  <h3>Dr. Javier</h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="card ca-shadow-sm border-0 p-4">
                  <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                  <p>
                    My treatment sessions have been great! My therapist has taught me how to
                    strengthen my core and abs to take pressure off of my back. I've been struggling with
                    nerve pain and after 5 sessions, it's so much better already.
                  </p>
                  <h3>Don G.</h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="card ca-shadow-sm border-0 p-4">
                  <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
                  <p>
                    I can't say enough good things. I love that my therapist emails me videos of the
                    stretches and exercises to do in between sessions and on my own. I recommend
                    Pete Health very highly to anyone experiencing back and sciatica.
                  </p>
                  <h3>Kendra M.</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Testimonials */}
    {/* Begin Compare us to others */}
    <div className="container text-center pt-5 pb-5 compare mt-n17">
      <h2 className="py-5">{compare.title}</h2>
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
      <div className="row">
        <div className="col-md-11 mx-auto  ca-shadow-sm rounded-2 bg-white px-5">
          <table className="table table-borderless">
            <thead>
            <tr className="py-4">
              <th scope="col" />
              <th className="text-uppercase" scope="col"><img className="widthmobileimg" src="/assets/newimg/logo.png" style={{width: '100px'}} alt="" /></th>
              <th className="text-uppercase" scope="col">
                <h2 className="theme-text-color widthmobileimg" style={{fontSize: '1.2rem'}}>Clinic</h2>
              </th>
            </tr></thead>
            <tbody className="text-left">
              {
                compare.section.map((section, sectionId) => {
                  return (
                    <React.Fragment key={sectionId}>
                      <tr className="bg-gray">
                        <td className="text-uppercase theme-text-color" colSpan={3} style={{"color": "#00234e !important"}}>{section.title}</td>
                      </tr>
                      {
                        compare.section[sectionId].list.map((item, i) => {
                          return (
                            <tr className={`${i === 3  ? '' : 'border-bottom'}`} key={i}>
                              <td className="pl-2 padding-pl" style={{"paddingTop": '24px !important', paddingBottom: '20px !important'}}>{item.text}</td>
                              <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                                {
                                  item.pete ? <img src="/assets/newimg/tick.png" alt="" /> : <img src="/assets/img/wrong.png" alt="" />
                                }
                              </td>
                              <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                                {
                                  item.clinic ? <img src="/assets/newimg/tick.png" alt="" /> : <img src="/assets/img/wrong.png" alt="" />
                                }
                              </td>
                            </tr>
                          );
                        })
                      }
                    </React.Fragment>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="row py-5">
        <div className="col">
          <div className="mb-1">
            <a className="btn btn-gr pl-3 pr-3 fw-6 mt-4" href="#" role="button" data-toggle="modal" data-target="#FormModalCenter">Schedule visit</a>
          </div>
          <br />
          <div className="f-7 fw-6 text-dark call">or call <a className="text-dark" href="tel:888-859-0145 " role="button">888-859-0145</a>
          </div>
        </div>
      </div>
    </div>
  </main>
);

WhyPageTemplate.propTypes = {
  banner: PropTypes.object,
  compare: PropTypes.object
};

const WhyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  console.log(frontmatter)
  return (
    <Layout>
      <WhyPageTemplate
        banner={frontmatter.banner}
        compare={frontmatter.compare}
      />
    </Layout>
  )
};

WhyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};


export default WhyPage;

export const pageQuery = graphql`
  query WhyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "why-page" } }) {
      frontmatter {
        compare {
          title
          section {
            title
            list {
              text
              pete
              clinic
            }
          }
        }
        banner {
          title
          btnTitle
          phone
          bannerImage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
