import React from 'react';

const Hiw = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <h2 className="hiw-title">How Pete Makes It Easy</h2>
        <p>Book, see and pay your trusted physical therapist in a breeze. This is physical therapy that works for you.
        </p>
        <div className="how-it-works">
          <div className="points">
            <div className="point-img-wrap float-left mr-3 position-relative">
              <img src="/assets/icons/why/5.png" alt="" className="position-absolute" />
            </div>
            <div className="points-text pt-2">
              <h3>Book your evaluation</h3>
              <p>
                We'll help coordinate the best time for a PeteHealth therapist to evaluate you.
              </p>
            </div>
          </div>
          <br />
          <div className="points">
            <div className="point-img-wrap float-left mr-3 position-relative">
              <img src="/assets/icons/why/6.png" alt="" className="position-absolute" />
            </div>
            <div className="points-text pt-2">
              <h3>Pay in a click</h3>
              <p>We accept most insurance or you can pay a flat fee per visit.
              </p>
            </div>
          </div>
          <br />
          <div className="points">
            <div className="point-img-wrap float-left mr-3 position-relative">
              <img src="/assets/icons/why/7.png" alt="" className="position-absolute" />
            </div>
            <div className="points-text pt-2">
              <h3>Get physical.</h3>
              <p>
                Easy and convenient, get the care you need in your home or office.
              </p>
            </div>
          </div>
        </div>
        <div className="how-it-works-mob">
          <div className="row">
            <div className="col-sm-4">
              <div className="point-img-wrap position-relative text-center">
                <img src="/assets/icons/why/5.png" alt="" className="position-absolute" />
              </div>
              <div className="points-text pt-2">
                <h3>Book your evaluation</h3>
                <p> We'll help coordinate the best time for a PeteHealth therapist to evaluate you.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="point-img-wrap position-relative text-center">
                <img src="/assets/icons/why/6.png" alt="" className="position-absolute" />
              </div>
              <div className="points-text pt-2">
                <h3>Pay in a click</h3>
                <p>We accept most insurance or you can pay a flat fee per visit.</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="point-img-wrap position-relative text-center">
                <img src="/assets/icons/why/7.png" alt="" className="position-absolute" />
              </div>
              <div className="points-text pt-2">
                <h3>Get physical</h3>
                <p>Easy and convenient, get the care you need in your home or office.</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <a className="btn btn-gr pl-3 pr-3 fw-6" href="#" role="button" data-toggle="modal" data-target="#FormModalCenter">Schedule visit</a>
        <div className="f-7 fw-6 text-dark mt-3 font-weight-bold hiw-call">or call <a className="text-dark" href="tel:888-859-0145" role="button">888-859-0145</a>
        </div>
      </div>
      <div className="col-md-6 works-2">
        <img src="/assets/newimg/2646.png" className="img-fluid desk" alt="" />
        <img src="/assets/newimg/2646.png" className="img-fluid mob" alt="" />
      </div>
    </div>
  )
};

export default Hiw;
